import React, { useState } from "react";
import { FaSchool } from "react-icons/fa";
import { IoStatsChart, IoLogOut } from "react-icons/io5";
import { FaUniversity } from "react-icons/fa";
import { IoMdSchool } from "react-icons/io";
import { AiFillCloseCircle } from "react-icons/ai";
import { MdInsertComment } from "react-icons/md";
import { RiAdminFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { BiCategory } from "react-icons/bi";

const Sidebar = ({
  isSidebarOpen,
  setIsSidebarOpen,
  currentTab,
  setCurrentTab,
}) => {
  const navigate = useNavigate();
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  function handleLogout() {
    window.location = "/";
    localStorage.removeItem("masterAdminToken");
    localStorage.removeItem("masterAdminEmail");
  }
  return (
    <div
      className={`${
        isSidebarOpen ? "sm:w-64 " : "w-0 sm:w-20 "
      } absolute z-20 flex h-screen flex-col items-center justify-start space-y-2 overflow-hidden bg-gray-100 transition-all sm:relative  sm:px-2 sm:pt-16`}
    >
      <button
        onClick={() => setIsSidebarOpen((pre) => !pre)}
        className="self-end rounded-full p-2 text-2xl hover:bg-gray-200 sm:hidden"
      >
        <AiFillCloseCircle />
      </button>
      <button
        onClick={() => setCurrentTab("university")}
        className={`${
          currentTab == "university"
            ? "bg-gray-300 text-black"
            : "text-gray-500"
        } flex w-full items-center justify-start space-x-2 rounded-2xl p-2 text-xl font-bold transition-all hover:bg-gray-300`}
      >
        <FaUniversity className="text-4xl" />
        <span className={`${!isSidebarOpen ? "w-0" : "w-fit"} overflow-hidden`}>
          Organizations
        </span>
      </button>
      <button
        onClick={() => setCurrentTab("college")}
        className={`${
          currentTab == "college" ? "bg-gray-300 text-black" : "text-gray-500"
        } flex w-full items-center justify-start space-x-2 rounded-2xl p-2 text-xl font-bold transition-all hover:bg-gray-300`}
      >
        <FaSchool className="text-4xl" />
        <span className={`${!isSidebarOpen ? "w-0" : "w-fit"} overflow-hidden`}>
          Department/Groups
        </span>
      </button>
      <button
        onClick={() => setCurrentTab("statement-categories")}
        className={`${
          currentTab == "statement-categories" ? "bg-gray-300 text-black" : "text-gray-500"
        } flex w-full items-center justify-start space-x-2 rounded-2xl p-2 text-xl font-bold transition-all hover:bg-gray-300`}
      >
        <BiCategory className="text-4xl" />
        <span className={`${!isSidebarOpen ? "w-0" : "w-fit"} overflow-hidden`}>
          Categories
        </span>
      </button>
      <button
        onClick={() => setCurrentTab("statement")}
        className={`${
          currentTab == "statement" ? "bg-gray-300 text-black" : "text-gray-500"
        } flex w-full items-center justify-start space-x-2 rounded-2xl p-2 text-xl font-bold transition-all hover:bg-gray-300`}
      >
        <MdInsertComment className="text-4xl" />
        <span className={`${!isSidebarOpen ? "w-0" : "w-fit"} overflow-hidden`}>
          Statements
        </span>
      </button>
      <button
        onClick={() => setCurrentTab("admin")}
        className={`${
          currentTab == "admin" ? "bg-gray-300 text-black" : "text-gray-500"
        } flex w-full items-center justify-start space-x-2 rounded-2xl p-2 text-xl font-bold transition-all hover:bg-gray-300`}
      >
        <RiAdminFill className="text-4xl" />
        <span className={`${!isSidebarOpen ? "w-0" : "w-fit"} overflow-hidden`}>
          Admins
        </span>
      </button>
      <button
        onClick={() => setCurrentTab("student")}
        className={`${
          currentTab == "student" ? "bg-gray-300 text-black" : "text-gray-500"
        } flex w-full items-center justify-start space-x-2 rounded-2xl p-2 text-xl font-bold transition-all hover:bg-gray-300`}
      >
        <IoMdSchool className="text-4xl" />
        <span className={`${!isSidebarOpen ? "w-0" : "w-fit"} overflow-hidden`}>
          Users
        </span>
      </button>

      <button
        onClick={handleLogout}
        className="absolute bottom-8 flex w-full items-center justify-start space-x-2 rounded-2xl p-2 text-xl font-bold transition-all hover:bg-gray-300"
      >
        <IoLogOut className="text-4xl" />
        <span className={`${!isSidebarOpen ? "w-0" : "w-fit"} overflow-hidden`}>
          LogOut
        </span>
      </button>
    </div>
  );
};

export default Sidebar;
