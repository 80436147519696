import axios from "axios";
const Modal = ({ isOpen, onClose, deleteObj }) => {
  async function handleDelete(obj) {
    try {
      let response;
      if (obj?.type == "Collage") {
        response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/admin/collage/${obj.id}`,
          method: "DELETE",
          headers: {
            email: localStorage.getItem("masterAdminEmail"),
            token: localStorage.getItem("masterAdminToken"),
          },
        });
      } else if (obj?.type == "Statement") {
        response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/admin/statement/${obj.id}`,
          method: "DELETE",
          headers: {
            email: localStorage.getItem("masterAdminEmail"),
            token: localStorage.getItem("masterAdminToken"),
          },
        });
      } else if (obj?.type == "Category") {
        response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/admin/category/${obj.id}`,
          method: "DELETE",
          headers: {
            email: localStorage.getItem("masterAdminEmail"),
            token: localStorage.getItem("masterAdminToken"),
          },
        });
      } else if (obj?.type == "Admin") {
        response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/admin/admins/${obj.id}`,
          method: "DELETE",
          headers: {
            email: localStorage.getItem("masterAdminEmail"),
            token: localStorage.getItem("masterAdminToken"),
          },
        });
      } else if (obj?.type == "University") {
        response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/admin/university/${obj.id}`,
          method: "DELETE",
          headers: {
            email: localStorage.getItem("masterAdminEmail"),
            token: localStorage.getItem("masterAdminToken"),
          },
        });
      }else if (obj?.type == "logo") {
        response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/admin/university/logo/deleteLogo`,
          method: "DELETE",
          headers: {
            email: localStorage.getItem("masterAdminEmail"),
            token: localStorage.getItem("masterAdminToken"),
          },
          data: {
            id: obj.id,
            universityId: obj.universityId,
          }
        });
      }

      if (response.status == 200) {
        // console.log(response?.data);
        onClose();
      }
    } catch (error) {
      alert(`Error while deleting ${obj.type}:` + error);
    }
  }

  return isOpen ? (
    <div className="fixed inset-0 z-30 overflow-y-auto ">
      <div className="flex min-h-screen items-center justify-center p-4">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-black opacity-75"></div>
        </div>
        <div className="relative flex max-h-full w-screen flex-col overflow-y-scroll rounded-lg bg-white p-[25px]  md:mx-auto md:w-[500px] md:overflow-y-auto ">
          <div className="mb-4">
            <h2 className="text-xl font-bold">{deleteObj?.title}</h2>
          </div>

          <div className="flex space-x-2 w-full justify-end">
            <button
              className="  rounded  bg-black px-4 py-2 text-white hover:bg-gray-400"
              onClick={() => handleDelete(deleteObj)}
            >
              Yes
            </button>
            <button
              className="  rounded  bg-gray-500 px-4 py-2 text-white hover:bg-gray-400"
              onClick={onClose}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Modal;
