import axios from "axios";
const Modal = ({ isOpen, onClose, viewColleges }) => {
  return isOpen ? (
    <div className="fixed inset-0 z-30 overflow-y-auto ">
      <div className="flex min-h-screen items-center justify-center p-4">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-black opacity-75"></div>
        </div>
        <div className="relative flex max-h-full w-screen flex-col overflow-y-scroll rounded-lg bg-white p-[25px]  md:mx-auto md:w-[500px] md:overflow-y-auto ">
          <div className="mb-4">
            <h2 className="text-xl font-bold">Available Department/Groups</h2>
          </div>

          <table className="w-full table-auto text-left text-gray-500 ">
            <thead className=" text-gray-700 uppercase bg-gray-50  ">
              <tr>
                <th scope="col" className="px-6 py-3">
                Department/Group name
                </th>
                <th scope="col" className="px-6 py-3">
                  Total Users
                </th>
              </tr>
            </thead>
            <tbody>
              {viewColleges?.map((college, index) => (
                <tr
                  key={college.collageName + index}
                  className="bg-white border-b   hover:bg-gray-50 "
                >
                  <td className="px-6 py-4">{college?.collageName}</td>
                  <td className="px-6 py-4 text-center">{college?.students}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            className="  rounded  bg-gray-500 px-4 py-2 text-white hover:bg-gray-400"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default Modal;
