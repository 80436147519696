import React, { useEffect, useState } from "react";
import { FaSchool } from "react-icons/fa";
import { IoMdSchool } from "react-icons/io";
import { RiAdminFill } from "react-icons/ri";
import { MdAddCircle, MdDelete, MdEdit, MdModeEdit } from "react-icons/md";
import UniversityModal from "../Components/UniversityModal";
import ViewCollagesModal from "../Components/ViewCollegesModal";
import ViewFormFieldsModal from "../Components/ViewFormFieldsModal";
import ViewLogoModal from "../Components/ViewLogoModal";
import CollageModal from "../Components/CollageModal";
import DeleteModal from "../Components/DeleteModal";
import axios from "axios";
export const University = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTxt, setSearchTxt] = useState("");
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isViewFormFieldsModalOpen, setIsViewFormFieldsModalOpen] =
    useState(false);
  const [isViewLogoModal, setisViewLogoModal] = useState(false);
  const [isAddCollage, setIsAddCollage] = useState(false);
  const [selectedUniId, setSelectedUniId] = useState();
  const [viewColleges, setViewColleges] = useState([]);
  const [universities, setUniversities] = useState([]);
  const [editObj, setEditObj] = useState({});
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [selectedUniversity, setselectedUniversity] = useState({})
  const [deleteObj, setDeleteObj] = useState({
    type: "University",
    title: "",
    id: null,
  });
  async function fetchUniversities() {
    setIsLoading(true);
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/admin/university`,
        method: "GET",
        headers: {
          email: localStorage.getItem("masterAdminEmail"),
          token: localStorage.getItem("masterAdminToken"),
        },
      });

      if (response.status == 200) {
        // console.log(response?.data?.universities);
        setIsLoading(false);
        return response?.data?.universities || [];
      }
    } catch (error) {
      setIsLoading(false);
      alert("Error while fetching organizations:" + error);
    }
  }

  async function handleSearch(value) {
    const universities = await fetchUniversities();
    const filteredUnis = await universities.filter((uni) =>
      uni.universityName.toLowerCase().includes(value.toLowerCase())
    );
    setUniversities(filteredUnis);
  }

  async function handleEdit(university) {
    // console.log(university);
    setEditObj(university);
    setIsModalOpen(true);
  }

  async function handleDelete(id, name) {
    // console.log(id);
    setDeleteObj((pre) => ({
      ...pre,
      id: id,
      title: `Are you sure you want to delete ${name} Organization?`,
    }));
    setIsDeleteModal(true);
  }

  useEffect(() => {
    (async function () {
      setUniversities(await fetchUniversities());
    })();
  }, []);

  return (
    <div className="flex flex-col items-center space-y-4 divide-y-2 py-8 h-full w-full px-4">
      <div className="flex flex-col items-center space-y-2">
        <button
          onClick={() => setIsModalOpen(true)}
          className="flex items-center justify-center space-x-1 rounded-lg bg-black p-2 text-2xl font-semibold text-white shadow-md transition-all hover:scale-105 md:p-4 md:text-4xl"
        >
          <MdAddCircle /> <span>Add Organization</span>
        </button>
      </div>
      <div>
        {" "}
        <input
          type="text"
          className="rounded-md form-input w-96"
          placeholder="Search"
          value={searchTxt}
          onChange={(e) => {
            setSearchTxt(e.target.value);
            handleSearch(e.target.value);
          }}
        />
      </div>
      <div className="relative  shadow-md sm:rounded-lg w-full h-full  py-2">
        {isLoading ? (
          <h1 className="text-xl self-center justify-self-center w-full text-center h-full">
            Loading...
          </h1>
        ) : universities?.length ? (
          <table className="w-full table-auto text-left text-gray-500 ">
            <thead className=" text-gray-700 uppercase bg-gray-50  ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Organization name
                </th>
                <th scope="col" className="px-6 py-3">
                  Organization URL
                </th>
                <th scope="col" className="px-6 py-3">
                  Total Department/Groups
                </th>
                <th scope="col" className="px-6 py-3">
                  Total Admins
                </th>
                <th scope="col" className="px-6 py-3">
                  Total Users
                </th>
                <th scope="col" className="px-6 py-3">
                  <span className="sr-only">View Department/Groups</span>
                </th>
                <th scope="col" className="px-6 py-3">
                  <span className="sr-only">Add Department/Group</span>
                </th>
                <th scope="col" className="px-6 py-3">
                  <span className="sr-only">Edit University</span>
                </th>
                <th scope="col" className="px-6 py-3">
                  <span className="sr-only">Delete University</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {universities?.map((university, index) => (
                <tr
                  key={university.universityName + index}
                  className="bg-white border-b   hover:bg-gray-50 "
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    <a
                      target={university.universitySlug ? "_blank" : "_self"}
                      href={
                        university.universitySlug
                          ? `${process.env.REACT_APP_FRONTEND_BASE_URL}/${university?.universitySlug}`
                          : ""
                      }
                    >
                      {university.universityName}
                    </a>
                  </th>
                  <td className="px-6 py-4">{university?.universitySlug}</td>
                  <td className="px-6 py-4">{university?.totalCollages}</td>
                  <td className="px-6 py-4">{university?.totalAdmins}</td>
                  <td className="px-6 py-4">{university?.totalStudents}</td>
                  <td className="px-6 py-4 text-right">
                    <a
                      href="#"
                      className="font-medium text-blue-600  hover:underline"
                      onClick={() => {
                        setSelectedUniId(university?.universityId);
                        setselectedUniversity(university);
                        setisViewLogoModal(true);
                      }}
                    >
                      Add / View Logos
                    </a>
                  </td>
                  <td className="px-6 py-4 text-right">
                    <a
                      href="#"
                      className="font-medium text-blue-600  hover:underline"
                      onClick={() => {
                        setSelectedUniId(university?.universityId);
                        setIsViewFormFieldsModalOpen(true);
                      }}
                    >
                      Form Fields
                    </a>
                  </td>
                  <td className="px-6 py-4 text-right">
                    <a
                      href="#"
                      className="font-medium text-blue-600  hover:underline"
                      onClick={() => {
                        setViewColleges(university?.collageWiseStudent);
                        setIsViewModalOpen(true);
                      }}
                    >
                      View Department/Groups
                    </a>
                  </td>
                  <td className="px-6 py-4 text-right">
                    <a
                      onClick={() => {
                        setSelectedUniId(university?.universityId);
                        setIsAddCollage(true);
                      }}
                      href="#"
                      className="font-medium text-blue-600  hover:underline"
                    >
                      Add Department/Group
                    </a>
                  </td>
                  <td className="px-6 py-4 text-right">
                    <MdEdit
                      onClick={() => handleEdit(university)}
                      className="text-4xl p-1 cursor-pointer hover:bg-gray-200 hover:scale-110 rounded-full"
                    />
                  </td>
                  <td className="px-6 py-4 text-right">
                    <MdDelete
                      onClick={() =>
                        handleDelete(
                          university?.universityId,
                          university?.universityName
                        )
                      }
                      className="text-4xl text-red-500 p-1 cursor-pointer hover:bg-gray-200 hover:scale-110 rounded-full"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h1 className="text-xl self-center justify-self-center w-full text-center h-full">
            No organizations found.
          </h1>
        )}
      </div>

      <UniversityModal
        editObj={editObj}
        isOpen={isModalOpen}
        onClose={async () => {
          setIsModalOpen(false);
          setEditObj({});
          setUniversities(await fetchUniversities());
        }}
      />
      <ViewCollagesModal
        viewColleges={viewColleges}
        isOpen={isViewModalOpen}
        onClose={() => {
          setIsViewModalOpen(false);
        }}
      />
      <CollageModal
        isOpen={isAddCollage}
        selectedUniId={selectedUniId}
        onClose={async () => {
          setIsAddCollage(false);
          setUniversities(await fetchUniversities());
        }}
      />
      <DeleteModal
        isOpen={isDeleteModal}
        onClose={async () => {
          setIsDeleteModal(false);
          setUniversities(await fetchUniversities());
        }}
        deleteObj={deleteObj}
      />

      <ViewFormFieldsModal
        isOpen={isViewFormFieldsModalOpen}
        onClose={() => {
          setIsViewFormFieldsModalOpen(false);
          setSelectedUniId();
        }}
        universityId={selectedUniId}
      />
      <ViewLogoModal
        isOpen={isViewLogoModal}
        onClose={() => {
          setisViewLogoModal(false);
          setSelectedUniId();
        }}
        selectedUniversity={selectedUniversity}
        universityId={selectedUniId}
      />
    </div>
  );
};
