import React from "react";
import Logo from "../Images/logo.png";
import { TfiMenu } from "react-icons/tfi";
function Navbar({ setSidebaar }) {
  return (
    <div className="top-0 z-10 flex h-16 w-full items-center justify-between bg-white px-4  py-1 shadow-xl transition-all md:sticky">
      <button
        onClick={() => setSidebaar((pre) => !pre)}
        className=" rounded-full p-2 text-2xl hover:bg-gray-200 "
      >
        <TfiMenu />
      </button>
      <img src={Logo} alt="Navbar logo" className="h-full object-scale-down" />
      <div className="flex items-center space-x-1">
        <img
          src={Logo}
          className="h-8 w-8 rounded-full border border-blue-500 object-cover"
          alt="admin logo"
        />
        <h1 className="font-bold">Admin</h1>
      </div>
    </div>
  );
}

export default Navbar;
