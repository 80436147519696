import React, { useEffect, useRef, useState } from "react";
import { IoMdSchool } from "react-icons/io";
import { MdAddCircle } from "react-icons/md";
import { MdDelete, MdEdit } from "react-icons/md";

import StatementModal from "../Components/StatementModal";
import DeleteModal from "../Components/DeleteModal";

import axios from "axios";
export const Statements = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTxt, setSearchTxt] = useState("");
  const [editObj, setEditObj] = useState({});
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteObj, setDeleteObj] = useState({
    type: "Statement",
    title: "",
    id: null,
  });
  const [statements, setStatements] = useState([]);
  const [universityDrop, setUniversityDrop] = useState([]);
  const [universityFilter, setUniversityFilter] = useState("");
  const universityDropdownRef = useRef();
  async function fetchStatements() {
    setIsLoading(true);
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/admin/statement`,
        method: "GET",
        headers: {
          email: localStorage.getItem("masterAdminEmail"),
          token: localStorage.getItem("masterAdminToken"),
        },
      });

      if (response.status == 200) {
        // console.log(response?.data?.statements);
        setIsLoading(false);
        return response?.data?.statements;
      }
    } catch (error) {
      setIsLoading(false);
      alert("Error while fetching Statements:" + error);
    }
  }

  async function handleEdit(statementId,categoryId, statement, colorCode, universityId) {
    // console.log(statementId, statement, universityId);
    setEditObj({ statementId,categoryId, statement, universityId, colorCode });
    setIsModalOpen(true);
  }

  async function handleDelete(id, name) {
    // console.log(id);
    setDeleteObj((pre) => ({
      ...pre,
      id: id,
      title: `Are you sure you want to delete ${name} statement?`,
    }));
    setIsDeleteModal(true);
  }

  function getAvailableUniversities(statements) {
    const universities = [];

    for (const statement of statements) {
      const universityId = statement.universityId;
      const universityName = statement.universityName;

      const existingUniversity = universities.find(
        (university) => university.universityId === universityId
      );
      if (!existingUniversity) {
        universities.push({ universityId, universityName });
      }
    }

    return universities;
  }

  async function filterStatements() {
    const statements = await fetchStatements();
    const filteredStatements = await statements.filter((statement) => {
      const universityMatch =
        universityFilter == "" || statement.universityId == universityFilter;

      return universityMatch;
    });

    setStatements(filteredStatements);
  }

  async function handleSearch(value) {
    setUniversityFilter("");
    const statements = await fetchStatements();
    const filteredStatements = await statements.filter((statement) =>
      statement.statement.toLowerCase().includes(value.toLowerCase())
    );
    setStatements(filteredStatements);
  }

  useEffect(() => {
    (async () => {
      const allStatements = await fetchStatements();
      setStatements(allStatements);
      setUniversityDrop(getAvailableUniversities(allStatements));
    })();
  }, []);

  useEffect(() => {
    filterStatements();
  }, [universityFilter]);

  return (
    <div className="flex flex-col items-center space-y-4 divide-y-2 w-full  py-8">
      <div className="flex flex-col items-center space-y-2">
        <button
          onClick={() => setIsModalOpen(true)}
          className="flex items-center justify-center space-x-1 rounded-lg bg-black p-2 text-2xl font-semibold text-white shadow-md transition-all hover:scale-105 md:p-4 md:text-4xl"
        >
          <MdAddCircle /> <span>Add Statements</span>
        </button>
      </div>
      <div className=" grid w-full md:w-4/5 xl:w-2/4 grid-cols-1 place-items-center gap-8 p-4   ">
        <div className="text-3xl flex justify-between w-full font-semibold px-4">
          <div className="flex items-center space-x-4">
            <span>All Statements</span>
            <select
              ref={universityDropdownRef}
              name="uni"
              id="uni"
              className="form-select rounded-md w-fit"
              value={universityFilter}
              onChange={(e) => setUniversityFilter(e.target.value)}
            >
              <option value="">Select Organization</option>
              {universityDrop?.map((university, index) => (
                <option
                  key={university.universityName + index}
                  value={university?.universityId}
                >
                  {university?.universityName}
                </option>
              ))}
            </select>
            <input
              type="text"
              className="rounded-md form-input w-fit"
              placeholder="Search"
              value={searchTxt}
              onChange={(e) => {
                setSearchTxt(e.target.value);
                handleSearch(e.target.value);
              }}
            />
          </div>

          <span className="text-gray-500">{statements?.length}</span>
        </div>

        {isLoading ? (
          <h1 className="text-xl self-center justify-self-center w-full h-full">
            Loading...
          </h1>
        ) : statements.length ? (
          statements.map((statement, index) => (
            <div
              key={statement?.statement + index}
              className=" flex w-full  items-center justify-between space-y-2 rounded-lg bg-white p-4 shadow-lg"
            >
              <div>
                <h1 className="md:text-3xl text-xl  font-bold">
                  {statement?.statement}
                </h1>
                <h1 className="md:text-2xl text-lg text-gray-500 font-semibold">
                  {statement?.universityName}
                </h1>
              </div>
              <div className="flex  justify-center space-x-4 space-y-2 ">
                <div>
                  <input
                    type="color"
                    value={statement.colorCode}
                    disabled
                    className="rounded-full overflow-hidden "
                  />
                  <p>Colour</p>
                </div>
                <button
                  onClick={() =>
                    handleEdit(
                      statement?.statementId,
                      statement?.categoryId,
                      statement?.statement,
                      statement?.colorCode,
                      statement?.universityId
                    )
                  }
                  className="text-4xl self-center text-gray-500 hover:scale-125"
                >
                  <MdEdit />
                </button>
                <button
                  onClick={() =>
                    handleDelete(statement?.statementId, statement?.statement)
                  }
                  className="text-4xl self-center text-red-500 hover:scale-125"
                >
                  <MdDelete />
                </button>
              </div>
            </div>
          ))
        ) : (
          <h1 className="text-xl self-center justify-self-center w-full h-full">
            No statements found.
          </h1>
        )}
      </div>

      <StatementModal
        editObj={editObj}
        isOpen={isModalOpen}
        onClose={async () => {
          setIsModalOpen(false);
          setEditObj({});
          setStatements(await fetchStatements());
        }}
      />

      <DeleteModal
        isOpen={isDeleteModal}
        onClose={async () => {
          setIsDeleteModal(false);
          setStatements(await fetchStatements());
        }}
        deleteObj={deleteObj}
      />
    </div>
  );
};
