import React, { useEffect, useRef, useState } from "react";
import { FaSchool } from "react-icons/fa";
import { BsFilePostFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import axios from "axios";

export const Students = () => {
  const [students, setStudents] = useState([]);
  const [universityDrop, setUniversityDrop] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [collageDrop, setCollageDrop] = useState([]);
  const [universityFilter, setUniversityFilter] = useState("");
  const [collageFilter, setCollageFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const universityDropdownRef = useRef();
  const collageDropdownRef = useRef();
  const studentsPageRef = useRef();
  const limit = 10; // Number of records to fetch per page
  async function getAvailableCollages(university) {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/admin/collage`,
        method: "GET",
        headers: {
          email: localStorage.getItem("masterAdminEmail"),
          token: localStorage.getItem("masterAdminToken"),
        },
      });

      if (response.status == 200) {
        // console.log(response?.data?.collages);
        const collages = response?.data?.collages?.map((collage) => ({
          collageId: collage.collageId,
          collageName: collage.collageName,
          universityId: collage.universityId,
        }));

        if (university) {
          const filteredColl = await collages?.filter(
            (collage) => collage.universityId == university
          );
          // console.log(filteredColl, university);
          setCollageDrop(filteredColl);
        } else {
          setCollageDrop(collages);
        }
      }
    } catch (error) {
      alert("Error while fetching Department/Groups:" + error);
      setCollageDrop([]);
    }
  }

  async function getAvailableUniversities() {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/admin/university`,
        method: "GET",
        headers: {
          email: localStorage.getItem("masterAdminEmail"),
          token: localStorage.getItem("masterAdminToken"),
        },
      });

      if (response.status == 200) {
        // console.log(response?.data?.universities);
        const universities = response?.data?.universities?.map((uni) => ({
          universityId: uni.universityId,
          universityName: uni.universityName,
        }));
        setUniversityDrop(universities);
      }
    } catch (error) {
      alert("Error while fetching organizations:" + error);
      return setUniversityDrop([]);
    }
  }

  async function fetchStudents(universityId, collageId, type) {
    setIsLoading(true);
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/admin/students?page=${
          type == "filter" ? 1 : page
        }&limit=${limit}&universityId=${universityId || ""}&collageId=${
          collageId || ""
        }`,
        method: "GET",
        headers: {
          email: localStorage.getItem("masterAdminEmail"),
          token: localStorage.getItem("masterAdminToken"),
        },
      });

      if (response.status == 200) {
        // console.log(response?.data?.students);
        setTotalCount(response.data?.totalCount);
        setIsLoading(false);
        return response?.data?.students;
      } else {
        setIsLoading(false);
        return new Array(0);
      }
    } catch (error) {
      // alert("Error while fetching Users:" + error);
      setIsLoading(false);
      return new Array(0);
    }
  }

  const handelInfiniteScroll = async (e) => {
    try {
      if (
        window.innerHeight + e.target.scrollTop >=
        e.target.scrollHeight + 63
      ) {
        // console.log("scroll detected page:", page);
        // console.log("scrollHeight" + e.target.scrollHeight);
        // console.log("innerHeight" + window.innerHeight);
        // console.log("scrollTop" + e.target.scrollTop);
        // Check if there are more records to fetch
        if (students?.length <= totalCount) {
          setPage((prevPage) => prevPage + 1);
          // console.log(students?.length, "totalCount", totalCount, "page", page);
          const allStudents =
            page == 1
              ? []
              : await fetchStudents(universityFilter, collageFilter);
          if (allStudents?.length) {
            setStudents((pre) => [...pre, ...allStudents]);
            setCollageDrop(getAvailableCollages(allStudents));
            setUniversityDrop(getAvailableUniversities(allStudents));
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function filterStudents(type, value) {
    let collageId = type === "collage" ? value : undefined;
    let universityId = type === "university" ? value : undefined;
    setStudents([]);
    const students = await fetchStudents(universityId, collageId, "filter");
    if (students.length) {
      setStudents(students);
    }
  }

  useEffect(() => {
    getAvailableCollages();
    getAvailableUniversities();

    (async () => {
      const allStudents = await fetchStudents();
      if (allStudents?.length) {
        students.length <= limit
          ? setStudents((pre) => [...allStudents])
          : setStudents((pre) => [...pre, ...allStudents]);
      }
    })();
  }, []);

  return (
    <div
      ref={studentsPageRef}
      onScroll={handelInfiniteScroll}
      className="flex overflow-y-auto  flex-col items-center space-y-4 divide-y-2 w-full  py-8"
    >
      <div className=" grid w-full md:w-4/5  xl:w-2/4 grid-cols-1 place-items-center gap-8 p-4   ">
        <div className="text-3xl flex justify-between w-full font-semibold px-4">
          <span>All Users</span>
          <span className="text-gray-500">{totalCount}</span>
        </div>
        <div className="flex w-full px-4 md:space-x-2">
          <select
            ref={universityDropdownRef}
            name="uni"
            id="uni"
            className="form-select rounded-md w-fit"
            value={universityFilter}
            onChange={async (e) => {
              setUniversityFilter(e.target.value);
              setPage(1);
              filterStudents("university", e.target.value);
              getAvailableCollages(e.target.value);
            }}
          >
            <option value="">Select Organization</option>
            {Array.isArray(universityDrop) &&
              universityDrop?.map((university, index) => (
                <option
                  key={university.universityName + index}
                  value={university?.universityId}
                >
                  {university?.universityName}
                </option>
              ))}
          </select>
          <select
            disabled={!universityFilter}
            ref={collageDropdownRef}
            name="collage"
            id="collage"
            className="form-select rounded-md w-fit"
            value={collageFilter}
            onChange={(e) => {
              setCollageFilter(e.target.value);
              setPage(1);
              filterStudents("collage", e.target.value);
            }}
          >
            <option value="">Select Department/Group</option>
            {Array.isArray(collageDrop) &&
              collageDrop?.map((collage, index) => (
                <option
                  key={collage.collageName + index}
                  value={collage?.collageId}
                >
                  {collage?.collageName}
                </option>
              ))}
          </select>
        </div>
        {isLoading && !students.length ? (
          <h1 className="text-xl self-center justify-self-center w-full h-full">
            Loading...
          </h1>
        ) : students.length ? (
          students.map((student, index) => (
            <div
              key={student?.firstName + index}
              className=" flex w-full  items-center justify-between space-y-2 rounded-lg bg-white p-4 shadow-lg"
            >
              <div className="flex flex-col ">
                <h1 className="text-xl md:text-2xl font-bold">
                  {student?.firstName} {student?.lastName}
                </h1>
                <h1 className=" m-0 flex flex-col md:flex-row justify-center md:justify-normal md:items-center md:space-x-2 text-sm md:text-lg text-gray-500 font-semibold">
                  <FaSchool className="md:inline-block hidden " />
                  <span className="text-gray-600 m-0">
                    {student?.collageName},{" "}
                  </span>
                  <span className="m-0">{student?.universityName}</span>
                </h1>
              </div>
              <div className="flex  justify-center space-x-4 space-y-2 ">
                <div className=" flex w-24 flex-col items-center justify-center rounded-md p-2 shadow-md sm:w-max ">
                  <BsFilePostFill className="text-2xl" />
                  <p className=" text-3xl">{student?.totalHeadshots}</p>
                  <p className="text-center">Total Heashots</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <h1 className="text-xl self-center justify-self-center w-full h-full">
            No Users were found
          </h1>
        )}
      </div>
    </div>
  );
};
