import axios from "axios";
import { nanoid } from "nanoid";
import React, { useEffect, useRef, useState } from "react";
import { MdDelete } from "react-icons/md";
import slugify from "slugify";
import AvatarEditorModal from "./AvatarEditorModal";
const Modal = ({ isOpen, onClose, editObj }) => {
  const [logo, setLogo] = useState(null);
  const [logoURL, setLogoURL] = useState(null);
  const [colleges, setColleges] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [statements, setStatements] = useState([]);
  const [slug, setSlug] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [isAvaliable, setIsAvaliable] = useState();
  const [universityName, setUniversityName] = useState("");
  const [croppedImage, setcroppedImage] = useState(null);
  const [croppedImageRaw, setcroppedImageRaw] = useState(null);
  const [isOpenModal, setisOpenModal] = useState(false);

  const [banner, setBanner] = useState();
  const universityInputRef = useRef();
  const universitySlugInputRef = useRef();
  const universityTypeInputRef = useRef();
  const collegeInputRef = useRef();
  const nameInputRef = useRef();
  const emailInputRef = useRef();
  const passInputRef = useRef();
  const statementInputRef = useRef();
  const colorInputRef = useRef();
  const titleInputRef = useRef();
  const descInputRef = useRef();
  const headColorInputRef = useRef();
  const carrCentInputRef = useRef();
  const aluAssoInputRef = useRef();
  const linAluNetInputRef = useRef();
  const carrCentInputLabelRef = useRef();
  const aluAssoInputLabelRef = useRef();
  const linAluNetInputLabelRef = useRef();
  const uniEmailInputRef = useRef();
  const uniPhoneInputRef = useRef();
  const formRef = useRef();
  const inputFileRef = useRef(null);

  // console.log(editObj, "university obj");

  async function handleSubmit(e) {
    // e.preventDefault();
    // setIsLoading(true);
    if (colleges.length && statements.length && admins.length && isAvaliable) {
      // e.preventDefault();
      const formData = new FormData();
      formData.append("universityName", universityInputRef.current.value);
      formData.append("orgnizationType", universityTypeInputRef.current.value);
      formData.append("universitySlug", universitySlugInputRef.current.value);
      formData.append("universityEmail", uniEmailInputRef.current.value);
      formData.append("universityPhone", uniPhoneInputRef.current.value);
      formData.append("universityTitle", titleInputRef.current.value);
      formData.append("universityDescription", descInputRef.current.value);
      formData.append("headerColor", headColorInputRef.current.value);
      formData.append("careerCenter", carrCentInputRef.current.value);
      formData.append("alumniAssociation", aluAssoInputRef.current.value);
      formData.append("linkedinAlumniNetwork", linAluNetInputRef.current.value);
      formData.append("careerCenterLabel", carrCentInputLabelRef.current.value);
      formData.append(
        "alumniAssociationLabel",
        aluAssoInputLabelRef.current.value
      );
      formData.append(
        "linkedinAlumniNetworkLabel",
        linAluNetInputLabelRef.current.value
      );
      formData.append("collages", JSON.stringify(colleges));
      formData.append("categories", JSON.stringify(statements));
      formData.append("admins", JSON.stringify(admins));

      const images = [croppedImageRaw, banner];
      // console.log(images);
      images.forEach((image) => {
        formData.append("images", image);
      });
      try {
        let response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/admin/university`,
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
            email: localStorage.getItem("masterAdminEmail"),
            token: localStorage.getItem("masterAdminToken"),
          },
          data: formData,
        });
        if (response.status == 200) {
          // console.log(response.data);
          setAdmins([]);
          setColleges([]);
          setLogo();
          setcroppedImage();
          setcroppedImageRaw();
          setBanner();
          setSlug("");
          setUniversityName("");
          setIsAvaliable();
          setIsChecking(false);
          setStatements([]);
          setIsLoading(false);
          onClose();
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    } else {
      e.preventDefault();
      alert(
        "Each Organization has to have atleast one statement, Department/Group and admin"
      );
      setIsLoading(false);
    }
  }

  async function handleUpdate(e) {
    // e.preventDefault();
    setIsLoading(true);
    if (isAvaliable) {
      // e.preventDefault();
      const formData = new FormData();
      formData.append("universityName", universityInputRef.current.value);
      formData.append("orgnizationType", universityTypeInputRef.current.value);
      formData.append("universitySlug", universitySlugInputRef.current.value);
      formData.append("universityEmail", uniEmailInputRef.current.value);
      formData.append("universityPhone", uniPhoneInputRef.current.value);
      formData.append("universityTitle", titleInputRef.current.value);
      formData.append("universityDescription", descInputRef.current.value);
      formData.append("headerColor", headColorInputRef.current.value);
      formData.append("careerCenter", carrCentInputRef.current.value);
      formData.append("alumniAssociation", aluAssoInputRef.current.value);
      formData.append("linkedinAlumniNetwork", linAluNetInputRef.current.value);
      formData.append("careerCenterLabel", carrCentInputLabelRef.current.value);
      formData.append(
        "alumniAssociationLabel",
        aluAssoInputLabelRef.current.value
      );
      formData.append(
        "linkedinAlumniNetworkLabel",
        linAluNetInputLabelRef.current.value
      );
      formData.append("collages", JSON.stringify(colleges));
      formData.append("categories", JSON.stringify(statements));
      formData.append("admins", JSON.stringify(admins));

      const images = [croppedImageRaw, banner];
      // console.log(images);
      images.forEach((image, index) => {
        formData.append("images", image, `${index}.png`);
      });
      try {
        let response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/admin/university/${editObj?.universityId}`,
          method: "PUT",
          headers: {
            "Content-Type": "multipart/form-data",
            email: localStorage.getItem("masterAdminEmail"),
            token: localStorage.getItem("masterAdminToken"),
          },
          data: formData,
        });
        if (response.status == 200) {
          // console.log(response.data);
          setAdmins([]);
          setColleges([]);
          setLogo();
          setcroppedImage();
          setcroppedImageRaw();
          setBanner();
          setSlug("");
          setUniversityName("");
          setIsAvaliable();
          setIsChecking(false);
          setStatements([]);
          setIsLoading(false);
          onClose();
        }
        // console.log(formData, "update called");
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    } else {
      e.preventDefault();
      alert("please check slug availability");
      setIsLoading(false);
    }
  }

  function handleLogo(e) {
    // console.log(e.target.files[0]);
    if (e.target.files[0]) {
      setLogo(e.target.files[0]);
      setLogoURL(URL.createObjectURL(e.target.files[0]));
      setisOpenModal(true);
    }
  }
  function clearLogo() {
    setLogo(null);
    setLogoURL(null);
    setcroppedImageRaw(null);
    setcroppedImage(null);
    // Reset the value of the file input by resetting the form
    if(inputFileRef.current) {
      inputFileRef.current.value = null;
    }
  }
  const onCloseModal = () => {
    clearLogo();
    setisOpenModal(false);
  };
  console.log(croppedImageRaw, "cropped imge raw");
  function handleBanner(e) {
    // console.log(e.target.files[0]);
    setBanner(e.target.files[0]);
  }

  function addCollege(e) {
    e.preventDefault();

    const collegeInput = collegeInputRef.current;
    const collegeName = collegeInput.value.trim();

    if (collegeName !== "") {
      setColleges([...colleges, collegeName]);
      collegeInput.value = "";
    }
  }

  function removeCollege(e, index) {
    e.preventDefault();
    const updatedColleges = [...colleges];
    updatedColleges.splice(index, 1);
    setColleges(updatedColleges);
  }
  function addAdmin(e) {
    e.preventDefault();
    const adminNameInput = nameInputRef.current;
    const adminEmailInput = emailInputRef.current;
    const adminPassInput = passInputRef.current;
    const name = adminNameInput.value.trim();
    const email = adminEmailInput.value.trim();
    const password = adminPassInput.value.trim();

    if (email !== "" && password !== "" && name !== "") {
      setAdmins([...admins, { name: name, email: email, password: password }]);
      adminNameInput.value = "";
      adminEmailInput.value = "";
      adminPassInput.value = "";
    }
  }

  function removeAdmin(e, index) {
    e.preventDefault();
    const updatedAdmins = [...admins];
    updatedAdmins.splice(index, 1);
    setAdmins(updatedAdmins);
  }
  function addStatement(e) {
    e.preventDefault();
    const statementInput = statementInputRef.current;
    // const colorInput = colorInputRef.current;
    const statement = statementInput.value.trim();
    // const color = colorInput.value;

    if (statement !== "") {
      setStatements([...statements, { category: statement }]);
      statementInput.value = "";
    }
  }

  function removeStatement(e, index) {
    e.preventDefault();
    const updatedStatments = [...statements];
    updatedStatments.splice(index, 1);
    setStatements(updatedStatments);
  }

  async function handleSlugGen() {
    let newSlug = slugify(universityName + "-" + nanoid(4), {
      lower: true,
      strict: true,
      trim: true,
      remove: /[*+~.()'"!:@]/g,
    });

    const isUnique = await handleAvaliable(newSlug);
    if (isUnique) {
      setSlug(newSlug);
      setIsAvaliable(true);
    } else {
      newSlug = slugify(universityName + "-" + nanoid(4), {
        lower: true,
        strict: true,
        trim: true,
        remove: /[*+~.()'"!:@]/g,
      });

      setSlug(newSlug);
    }
  }

  async function handleAvaliable(slug) {
    setIsChecking(true);
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/admin/checkSlug`,
        method: "POST",
        data: { slug },
      });
      setIsChecking(false);
      return response?.data?.isUnique;
    } catch (error) {
      console.log(error);
      setIsChecking(false);
      return false;
    }
  }

  useEffect(() => {
    editObj?.logo &&
      axios({
        url: `${process.env.REACT_APP_BASE_URL}/${editObj?.logo}`,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        setLogo(new Blob([response.data], { type: "image/png" }));
        setcroppedImageRaw(new Blob([response.data], { type: "image/png" }));
      });
    editObj?.banner &&
      axios({
        url: `${process.env.REACT_APP_BASE_URL}/${editObj?.banner}`,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        setBanner(new Blob([response.data], { type: "image/png" }));
      });
    editObj?.universityName && setUniversityName(editObj?.universityName);
    editObj?.universitySlug && setSlug(editObj?.universitySlug);
    editObj?.universitySlug && setIsAvaliable(true);
  }, [editObj]);

  return (
    <>
      {isOpen ? (
        <div
          className={` ${
            !isOpen ? "hidden" : "block"
          } fixed inset-0 z-30 overflow-y-auto`}
        >
          <div className="flex min-h-screen items-center justify-center p-4">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-black opacity-75"></div>
            </div>
            <div className="relative flex max-h-full w-screen flex-col overflow-y-scroll rounded-lg bg-white p-[25px]  md:mx-auto md:w-fit md:min-w-[500px] md:overflow-y-auto md:p-6">
              <div className="mb-4">
                <h2 className="text-xl font-bold">
                  {editObj?.universityId
                    ? "Edit Organization"
                    : "Add New Organization"}
                </h2>
              </div>
              <div className="">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    editObj?.universityId ? handleUpdate(e) : handleSubmit(e);
                    e.stopPropagation();
                  }}
                  ref={formRef}
                  className="space-y-4 divide-y-2"
                >
                  <div className="flex flex-col flex-wrap  justify-center  space-y-4  md:justify-normal">
                    <div className="flex space-x-2">
                      <input
                        ref={universityInputRef}
                        required
                        type="text"
                        id="uniName"
                        value={universityName}
                        onChange={(e) => setUniversityName(e.target.value)}
                        className="form-input h-fit w-fit rounded-lg"
                        placeholder="Enter Organization Name"
                      />

                      <input
                        required
                        type="email"
                        ref={uniEmailInputRef}
                        defaultValue={editObj?.universityEmail}
                        id="uniEmailInput"
                        className="form-input rounded-lg "
                        placeholder="Enter Organization Email"
                      />
                      <input
                        required
                        type="number"
                        defaultValue={editObj?.universityPhone}
                        ref={uniPhoneInputRef}
                        id="uniPhoneInput"
                        className="form-input rounded-lg "
                        placeholder="Enter Organization Phone"
                      />
                    </div>
                    <div className="flex space-x-2 w-full  ">
                      <input
                        defaultValue={editObj?.title}
                        required
                        type="text"
                        ref={titleInputRef}
                        id="titleInput"
                        className="form-input rounded-lg  w-1/2"
                        placeholder="Enter Organization Title"
                      />
                      <textarea
                        defaultValue={editObj?.description}
                        required
                        ref={descInputRef}
                        id="descInput"
                        className="form-input rounded-lg  w-1/2"
                        placeholder="Enter Description"
                      />
                    </div>
                    <div className="flex space-x-2">
                      <img
                        className="h-16 w-16 rounded-full object-cover"
                        src={
                          logo && croppedImageRaw
                            ? URL.createObjectURL(croppedImageRaw)
                            : "https://upload.wikimedia.org/wikipedia/commons/2/24/Circle-icons-image.svg"
                        }
                        alt="Current profile photo"
                      />
                      <label className="block">
                        <span className="font-semibold">
                          Choose Organization Logo
                        </span>
                        <input
                          required={!editObj?.logo}
                          type="file"
                          accept="image/*"
                          onChange={handleLogo}
                          className="file:bg-gray-50-50 block w-full text-sm text-slate-500 file:mr-4 file:rounded-full file:border-0 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-gray-700 hover:file:bg-gray-100"
                        />
                      </label>

                      <img
                        className="h-16 w-16 rounded-full object-cover"
                        src={
                          banner
                            ? URL.createObjectURL(banner)
                            : "https://upload.wikimedia.org/wikipedia/commons/2/24/Circle-icons-image.svg"
                        }
                        alt="Current profile photo"
                      />
                      <label className="block">
                        <span className="font-semibold">
                          Choose Organization Banner
                        </span>
                        <input
                          required={!editObj?.banner}
                          type="file"
                          accept="image/*"
                          onChange={handleBanner}
                          className="file:bg-gray-50-50 block w-full text-sm text-slate-500 file:mr-4 file:rounded-full file:border-0 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-gray-700 hover:file:bg-gray-100"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="flex flex-col items-end justify-center space-x-4 space-y-4 md:flex-row md:justify-normal">
                    <label htmlFor="uniName" className="flex flex-col">
                      <span className="font-semibold">Organization URL</span>
                      <input
                        ref={universitySlugInputRef}
                        required
                        type="text"
                        id="uniName"
                        disabled={!universityName.length > 0}
                        value={slug || editObj?.universitySlug}
                        onChange={async (e) => {
                          setSlug(e.target.value);
                          editObj?.universitySlug === e.target.value
                            ? setIsAvaliable(true)
                            : setIsAvaliable(
                                await handleAvaliable(e.target.value)
                              );
                        }}
                        className="form-input h-fit w-fit rounded-lg"
                        placeholder="Enter Organization URL"
                      />
                    </label>

                    <button
                      type="button"
                      disabled={!universityName.length > 0}
                      onClick={handleSlugGen}
                      className="rounded bg-gray-500 px-4 py-2 text-white"
                    >
                      Generate
                    </button>
                    <p>
                      {isChecking && <span>Checking Availability...</span>}
                      {isChecking == false && isAvaliable && (
                        <span className="text-green-600">URL Available</span>
                      )}
                      {isChecking == false && isAvaliable == false && (
                        <span className="text-red-600">URL not Available</span>
                      )}
                    </p>
                  </div>
                  <div className="flex flex-col items-end justify-center space-x-4 space-y-4 md:flex-row md:justify-normal">
                    <label htmlFor="uniName" className="flex flex-col">
                      <span className="font-semibold">Type of Organization</span>
                      <select
                      ref={universityTypeInputRef}
                      required
                      className=" rounded-lg "
                      defaultValue={editObj?.orgnizationType || "0"}
                      >
                        <option value="0">College/University</option>
                        <option value="1">Association</option>
                        <option value="2">Conference Exhibitor</option>
                        <option value="3">Event</option>
                        <option value="4">Health Club</option>
                        <option value="5">Brand</option>
                        <option value="6">Employer</option>
                        <option value="7">College Recruiter</option>
                        <option value="8">Staffing Agency</option>
                        <option value="9">Job Board</option>
                        

                      </select>
                    </label>
                  </div>
                  <div className="flex w-full space-y-2 flex-col items-center justify-center  md:items-start">
                    <p className="self-start text-lg font-semibold">
                      Organization Resources
                    </p>

                    <div className="flex flex-col items-center w-full   justify-center  space-y-2 md:justify-normal">
                      <div className=" space-x-9 w-full">
                        <label
                          className="w-1/3 space-x-2"
                          htmlFor="headerColorInput"
                        >
                          <span>Select Header Colour</span>
                          <input
                            required
                            type="color"
                            ref={headColorInputRef}
                            id="headerColorInput"
                            defaultValue={editObj?.headerColor}
                            // onChange={(e) => console.log(e.target.value)}
                            className=" rounded-lg "
                            placeholder="Select Color"
                          />
                        </label>
                      </div>
                      <div className=" space-x-2 w-full">
                        <input
                          required
                          defaultValue={editObj?.careerCenterLabel}
                          type="text"
                          ref={carrCentInputLabelRef}
                          id="carrCentLabelInput"
                          className="form-input rounded-lg  w-1/3"
                          placeholder="Enter Resource 1 Label"
                        />
                        <input
                          required
                          defaultValue={editObj?.careerCenter}
                          type="text"
                          ref={carrCentInputRef}
                          id="carrCentInput"
                          className="form-input rounded-lg  w-1/3"
                          placeholder="Enter Resource 1 URL"
                        />
                      </div>
                      <div className=" space-x-2 w-full">
                        <input
                          required
                          defaultValue={editObj?.alumniAssociationLabel}
                          type="text"
                          ref={aluAssoInputLabelRef}
                          id="aluAssoLabelInput"
                          className="form-input rounded-lg w-1/3"
                          placeholder="Enter Resource 2 Label"
                        />
                        <input
                          required
                          defaultValue={editObj?.alumniAssociation}
                          type="text"
                          ref={aluAssoInputRef}
                          id="aluAssoInput"
                          className="form-input rounded-lg w-1/3"
                          placeholder="Enter Resource 2 URL"
                        />
                      </div>
                      <div className=" space-x-2 w-full">
                        <input
                          required
                          defaultValue={editObj?.linkedinAlumniNetworkLabel}
                          type="text"
                          ref={linAluNetInputLabelRef}
                          id="linAluNetLabelInput"
                          className="form-input rounded-lg w-1/3"
                          placeholder="Enter Resource 3 URL"
                        />
                        <input
                          required
                          defaultValue={editObj?.linkedinAlumniNetwork}
                          type="text"
                          ref={linAluNetInputRef}
                          id="linAluNetInput"
                          className="form-input rounded-lg w-1/3"
                          placeholder="Enter Resource 3 URL"
                        />
                      </div>
                      {/* <div className=" space-x-2 w-full">
                    <input
                      required
                      defaultValue={editObj?.alumniAssociation}
                      type="text"
                      ref={aluAssoInputRef}
                      id="aluAssoInput"
                      className="form-input rounded-lg w-1/3"
                      placeholder="Enter Alumni Association URL"
                    />
                    <input
                      required
                      defaultValue={editObj?.linkedinAlumniNetwork}
                      type="text"
                      ref={linAluNetInputRef}
                      id="linAluNetInput"
                      className="form-input rounded-lg w-1/3"
                      placeholder="Linkedin Alumni Network URL"
                    />
                  </div> */}
                    </div>
                  </div>
                  {editObj?.universityId ? null : (
                    <>
                      <div className="flex w-full flex-col items-center justify-center  md:items-start">
                        <p className="self-start text-lg font-semibold">
                          Add Department/Groups
                        </p>

                        <div className="flex items-center justify-center space-x-2 md:justify-normal">
                          <input
                            type="text"
                            ref={collegeInputRef}
                            id="collegeInput"
                            className="form-input rounded-lg "
                            placeholder="Enter Department/Group Name"
                          />
                          <button
                            className="rounded bg-gray-500 px-4 py-2 text-white"
                            onClick={addCollege}
                          >
                            Add
                          </button>
                        </div>
                        <table className="ml-2 w-full md:w-full">
                          <tbody className="ml-2 w-full md:w-full">
                            {colleges.map((collage, index) => (
                              <tr
                                key={index}
                                className="mt-2 flex w-full items-center  space-x-4 "
                              >
                                <td>{index + 1})</td>
                                <td className="w-2/4 md:overflow-x-auto overflow-x-scroll md:w-3/4">
                                  {collage}
                                </td>

                                <td>
                                  <button
                                    className="rounded bg-red-500 px-2 py-1 text-white"
                                    onClick={(e) => removeCollege(e, index)}
                                  >
                                    <MdDelete />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="flex w-full flex-col items-center justify-center  md:items-start">
                        <p className="self-start text-lg font-semibold">
                          Add Satement Category
                        </p>

                        <div className="flex flex-col md:flex-row items-center justify-center space-x-2 md:justify-normal">
                          <input
                            type="text"
                            ref={statementInputRef}
                            id="statmentInput"
                            className="form-input rounded-lg"
                            placeholder="Enter Satement Category"
                          />
                          {/* <label htmlFor="colorInput">Select Colour</label>
                          <input
                            type="color"
                            defaultValue={"#ffc800"}
                            ref={colorInputRef}
                            id="colorInput"
                            // onChange={(e) => console.log(e.target.value)}
                            className=" rounded-lg"
                            placeholder="Select Color"
                          /> */}
                          <button
                            className="rounded bg-gray-500 px-4 py-2 text-white"
                            onClick={addStatement}
                          >
                            Add
                          </button>
                        </div>
                        <table className="ml-2 w-full md:w-full">
                          <tbody className="ml-2 w-full md:w-full">
                            {statements.map((statement, index) => (
                              <tr
                                key={index}
                                className="mt-2 flex w-full items-center  space-x-4 "
                              >
                                <td>{index + 1})</td>
                                <td className="w-2/4 md:overflow-x-auto overflow-x-scroll md:w-3/4">
                                  {statement?.category}
                                </td>

                                {/* <td>
                                  <input
                                    type="color"
                                    value={statement?.color}
                                    disabled
                                  />
                                </td> */}
                                <td>
                                  <button
                                    className="rounded bg-red-500 px-2 py-1 text-white"
                                    onClick={(e) => removeStatement(e, index)}
                                  >
                                    <MdDelete />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="flex w-full flex-col items-center justify-center  md:items-start">
                        <p className="self-start text-lg font-semibold">
                          Add Admins
                        </p>

                        <div className="flex flex-col items-center justify-center space-y-2 md:space-y-0 md:flex-row md:space-x-2">
                          <input
                            type="text"
                            ref={nameInputRef}
                            id="nameInput"
                            className="form-input rounded-lg"
                            placeholder="Enter Name"
                          />
                          <input
                            type="email"
                            ref={emailInputRef}
                            id="emailInput"
                            className="form-input rounded-lg"
                            placeholder="Enter Email"
                          />
                          <input
                            type="text"
                            ref={passInputRef}
                            id="passInput"
                            className="form-input rounded-lg"
                            placeholder="Enter Password"
                          />
                          <button
                            onClick={(e) => addAdmin(e)}
                            className="rounded bg-gray-500 px-4 py-2 text-white"
                          >
                            Add
                          </button>
                        </div>
                        <table className="ml-2 w-full md:w-full">
                          <tbody className="ml-2 w-full md:w-full">
                            {admins.map((admin, index) => (
                              <tr
                                key={index}
                                className="mt-2 flex w-full items-center  space-x-4 "
                              >
                                <td>{index + 1})</td>
                                <td className="w-2/4 md:overflow-x-auto overflow-x-scroll md:w-3/4">
                                  {admin?.name}
                                </td>
                                <td className="w-2/4 md:overflow-x-auto overflow-x-scroll md:w-3/4">
                                  {admin?.email}
                                </td>
                                <td className="w-1/4 md:overflow-x-auto overflow-x-scroll md:w-3/4">
                                  {admin?.password}
                                </td>
                                <td>
                                  <button
                                    className="rounded bg-red-500 px-2 py-1 text-white"
                                    onClick={(e) => removeAdmin(e, index)}
                                  >
                                    <MdDelete />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                  <div className="flex justify-end w-full py-2 space-x-2">
                    <button
                      disabled={isLoading}
                      type="submit"
                      className="  rounded  bg-black px-4 py-2 text-white hover:bg-gray-600"
                    >
                      {isLoading ? "Loading..." : "Submit"}
                    </button>
                    <button
                      className="  rounded  bg-gray-500 px-4 py-2 text-white hover:bg-gray-400"
                      onClick={() => {
                        setAdmins([]);
                        setColleges([]);
                        setLogo();
                        setcroppedImage();
                        setcroppedImageRaw();
                        setBanner();
                        setSlug("");
                        setUniversityName("");
                        setIsAvaliable();
                        setIsChecking(false);
                        setStatements([]);
                        setIsLoading(false);
                        onClose();
                      }}
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <AvatarEditorModal
        isOpenModal={isOpenModal}
        setisOpenModal={setisOpenModal}
        onCloseModal={onCloseModal}
        logoURL={logoURL}
        croppedImage={croppedImage}
        setcroppedImage={setcroppedImage}
        setcroppedImageRaw={setcroppedImageRaw}
      />
    </>
  );
};

export default Modal;
