import axios from "axios";
import { useEffect, useState } from "react";
const Modal = ({ isOpen, onClose, universityId }) => {
  const [formFields, setFormFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  async function fetchFormFields() {
    setIsLoading(true);
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/admin/university/formfields/${universityId}`,
        method: "GET",
        headers: {
          email: localStorage.getItem("masterAdminEmail"),
          token: localStorage.getItem("masterAdminToken"),
        },
      });

      if (response.status == 200) {
        setIsLoading(false);
        setFormFields(response?.data?.formFields);
      }
    } catch (error) {
      setIsLoading(false);
      alert("Error while fetching formfields:" + error);
    }
  }
  async function handleToggle(questionId) {
    setIsLoading(true);
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/admin/university/formfields/toggle`,
        method: "POST",
        headers: {
          email: localStorage.getItem("masterAdminEmail"),
          token: localStorage.getItem("masterAdminToken"),
        },
        data:{
          universityId: universityId,
          questionId:questionId,
        }
      });

      if (response.status == 200) {
        
        await fetchFormFields();
        
      }
    } catch (error) {
      setIsLoading(false);
      alert("Error while fetching formfields:" + error);
    }
  }

  useEffect(() => {
    fetchFormFields();
  }, [universityId]);

  return isOpen ? (
    <div className="fixed inset-0 z-30  ">
      <div className="flex   items-center justify-center p-4">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-black opacity-75"></div>
        </div>
        <div className="relative flex max-h-[90vh] w-screen flex-col  rounded-lg bg-white p-[25px]  md:mx-auto md:w-[500px]  ">
          <div className="mb-4">
            <h2 className="text-xl font-bold">Available Form Fields</h2>
          </div>

          <div className="overflow-y-scroll max-h-[90vh] w-full">
            {isLoading ? (
              <h1 className="text-xl h-[90vh] self-center justify-self-center w-full text-center h-full">
                Loading...
              </h1>
            ) : (
              <table className="w-full  table-auto text-left text-gray-500 ">
                <thead className=" text-gray-700 uppercase bg-gray-50  ">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Field
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Enable/Disable
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {formFields?.map((qn, index) => (
                    <tr
                      key={qn.questionId + index}
                      className="bg-white border-b   hover:bg-gray-50 "
                    >
                      <td className="px-6 py-4">{qn?.question}</td>
                      <td className="px-6 py-4 text-center">
                        <label className={`relative inline-flex items-center mb-5 ${qn?.canDisable && 'cursor-pointer'}`}>
                          <input
                            type="checkbox"
                            value=""
                            className="sr-only peer"
                            defaultChecked={qn?.isEnabled}
                            disabled={!qn?.canDisable}
                            onChange={()=>handleToggle(qn?.questionId)}
                          />
                          <div className="w-9 h-5 bg-gray-200  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-yellow-500 peer-disabled:opacity-25"></div>
                        </label>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <button
            className="  rounded  bg-gray-500 px-4 py-2 text-white hover:bg-gray-400"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default Modal;
