import React, { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
function AvatarEditorModal({
  isOpenModal,
  setisOpenModal,
  onCloseModal,
  croppedImage,
  setcroppedImage,
  setcroppedImageRaw,
  logoURL,
}) {
  const [zoomFactor, setzoomFactor] = useState(1.2);
  return isOpenModal ? (
    <div
      className={` ${
        !isOpenModal ? "hidden" : "block"
      } fixed inset-0 z-30 overflow-y-auto`}
    >
      <div className="flex min-h-screen items-center justify-center p-4">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-black opacity-75"></div>
        </div>
        <div className="relative flex max-h-full w-screen flex-col overflow-y-scroll rounded-lg bg-white p-[25px]  md:mx-auto md:w-fit md:min-w-[500px] md:overflow-y-auto md:p-6">
          <CircularImageCrop
            image={logoURL}
            croppedImage={croppedImage}
            setcroppedImage={setcroppedImage}
            zoomFactor={zoomFactor}
            setzoomFactor={setzoomFactor}
            setisOpenModal={setisOpenModal}
            setcroppedImageRaw={setcroppedImageRaw}
          />
          <div className="flex justify-end w-full py-2 space-x-2">
            <button
              className="  rounded  bg-gray-500 px-4 py-2 text-white hover:bg-gray-400"
              onClick={() => {
                onCloseModal();
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default AvatarEditorModal;
const CircularImageCrop = ({
  image,
  setcroppedImage,
  setcroppedImageRaw,
  zoomFactor,
  setzoomFactor,
  setisOpenModal
}) => {
  const editorRef = useRef();

  const croppedSize = 300; // Specify the desired size of the cropped image

  // ...
  const handleCrop = async () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      const { blob, dataUrl } = await applyCircularMask(canvas.toDataURL(), croppedSize);
      setcroppedImageRaw(blob);
      setcroppedImage(dataUrl);
      setisOpenModal(false);
    }
  };
  
  const applyCircularMask = (imageDataUrl, size) => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
  
      canvas.width = size;
      canvas.height = size;
  
      const radius = size / 2;
  
      context.beginPath();
      context.arc(radius, radius, radius, 0, 2 * Math.PI, false);
      context.clip();
  
      const image = new Image();
      image.onload = () => {
        context.drawImage(image, 0, 0, size, size);
  
        canvas.toBlob(
          (blob) => {
            if (!blob) {
              reject(new Error("Failed to create blob."));
              return;
            }
            resolve({
              blob: blob,
              dataUrl: canvas.toDataURL() // Data URL of the cropped image
            });
          },
          "image/png", // You can change the format here if needed
          1 // Quality parameter for PNG format (ignored for other formats)
        );
      };
      image.src = imageDataUrl;
    });
  };
  

  return (
    <div className="flex flex-col items-center justify-center">
      <AvatarEditor
        ref={editorRef}
        image={image}
        width={250}
        height={250}
        border={50}
        borderRadius={250}
        color={[255, 255, 255, 0.6]} // Color of the crop border
        scale={zoomFactor} // Zoom factor
        rotate={0} // Rotation angle in degrees
      />
      <div className="flex flex-row gap-2 w-100 m-0 p-0">
        <div>
          <button
            type="button"
            className="w-full rounded-md py-2 bg-[#FBB522] px-4 hover:bg-[#dfa423] hover:text-[#fff] font-semibold active:bg-[#dfa423] focus:outline-none focus:ring focus:ring-[#dfa423] ..."
            onClick={() => {
              setzoomFactor((prev) => prev - 0.1);
            }}
          >
            -
          </button>
        </div>
        <div>
          <button
            type="button"
            className="w-full rounded-md py-2 bg-[#FBB522] px-4 hover:bg-[#dfa423] hover:text-[#fff] font-semibold active:bg-[#dfa423] focus:outline-none focus:ring focus:ring-[#dfa423] ..."
            onClick={handleCrop}
          >
            Adjust & Save Crop
          </button>
        </div>
        <div>
          <button
            type="button"
            className="w-full rounded-md py-2 bg-[#FBB522] px-4 hover:bg-[#dfa423] hover:text-[#fff] font-semibold active:bg-[#dfa423] focus:outline-none focus:ring focus:ring-[#dfa423] ..."
            onClick={() => {
              setzoomFactor((prev) => prev + 0.1);
            }}
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
};
