import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
const Modal = ({ isOpen, onClose, editObj }) => {
  const [universities, setUniversities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const statementInputRef = useRef();
  const colorInputRef = useRef();
  const universityDropdownRef = useRef();
  const categoryDropdownRef = useRef();
  const formRef = useRef();

  // console.log(editObj, "statement modal view");
  async function handleEdit(e) {
    setIsLoading(true);
    try {
      e.preventDefault();
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/admin/category/${editObj?.categoryId}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          email: localStorage.getItem("masterAdminEmail"),
          token: localStorage.getItem("masterAdminToken"),
        },
        data: {
          universityId: universityDropdownRef.current.value,
          category: statementInputRef.current.value,
        },
      });
      if (response.status == 200) {
        e.preventDefault();
        // console.log(response.data);
        statementInputRef.current.value = "";
        setIsLoading(false);
        onClose();
      }
    } catch (error) {
      e.preventDefault();
      console.log(error);
      alert("Error while editing statement : " + error);
      setIsLoading(false);
    }
  }

  async function handleSubmit(e) {
    setIsLoading(true);
    try {
      e.preventDefault();
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/admin/category`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          email: localStorage.getItem("masterAdminEmail"),
          token: localStorage.getItem("masterAdminToken"),
        },
        data: {
          universityId: universityDropdownRef.current.value,
          category: statementInputRef.current.value,
        },
      });
      if (response.status == 200) {
        e.preventDefault();
        // console.log(response.data);
        statementInputRef.current.value = "";
        setIsLoading(false);
        onClose();
      }
    } catch (error) {
      e.preventDefault();
      console.log(error);
      alert("Error while creating statement : " + error);
      setIsLoading(false);
    }
  }

  async function fetchUniversities() {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/admin/university`,
        method: "GET",
        headers: {
          email: localStorage.getItem("masterAdminEmail"),
          token: localStorage.getItem("masterAdminToken"),
        },
      });

      if (response.status == 200) {
        // console.log(response?.data?.universities);
        setUniversities(response?.data?.universities);
      }
    } catch (error) {
      alert("Error while fetching organizations:" + error);
    }
  }
  async function fetchUniversityCategory() {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/admin/university`,
        method: "GET",
        headers: {
          email: localStorage.getItem("masterAdminEmail"),
          token: localStorage.getItem("masterAdminToken"),
        },
      });

      if (response.status == 200) {
        // console.log(response?.data?.universities);
        setCategories(response?.data?.universities);
      }
    } catch (error) {
      alert("Error while fetching organizations:" + error);
    }
  }

  useEffect(() => {
    fetchUniversities();
  }, []);

  return isOpen ? (
    <div className="fixed inset-0 z-30 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-black opacity-75"></div>
        </div>
        <div className="relative flex max-h-full w-screen flex-col overflow-y-scroll rounded-lg bg-white p-[25px]  md:mx-auto md:w-[500px] md:overflow-y-auto ">
          <div className="mb-4">
            <h2 className="text-xl font-bold">
              {editObj?.statementId ? "Edit Statement Category" : "Add New Category"}
            </h2>
          </div>
          <div className="">
            <form
              onSubmit={(e) => {
                editObj?.categoryId ? handleEdit(e) : handleSubmit(e);
                return false;
              }}
              ref={formRef}
              className="space-y-2 divide-y-2"
            >
              <div className="flex w-full flex-col items-center justify-center  md:items-start">
                <p className="self-start text-lg font-semibold">Organization</p>
                <select
                  ref={universityDropdownRef}
                  required
                  name="uni"
                  defaultValue={editObj?.universityId}
                  id="uni"
                  className="form-select rounded-md w-full"
                >
                  <option value="">Select Organization</option>
                  {universities?.map((university, index) => (
                    <option
                      key={university?.universityName + index}
                      value={university?.universityId}
                    >
                      {university?.universityName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex w-full flex-col items-center justify-center  md:items-start">
                <p className="self-start text-lg font-semibold">Statement Category</p>
                <input
                  required
                  type="text"
                  defaultValue={editObj?.category}
                  ref={statementInputRef}
                  id="statementInput"
                  className="form-input rounded-lg w-full"
                  placeholder="Enter statement"
                />
              </div>
              <div className="flex justify-end w-full py-2 space-x-2">
                <button
                  disabled={isLoading}
                  type="submit"
                  className="  rounded  bg-black px-4 py-2 text-white hover:bg-gray-600"
                >
                  {isLoading ? "Loading..." : "Submit"}
                </button>
                <button
                  className="  rounded  bg-gray-500 px-4 py-2 text-white hover:bg-gray-400"
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Modal;
