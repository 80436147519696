import axios from "axios";
import React, { useRef, useState } from "react";
import { RiAdminFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import logo from "../Images/logo.png";
export const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const emailInputRef = useRef();
  const passInputRef = useRef();
  const navigate = useNavigate();
  async function handleLogin(e) {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/auth/master`,
        method: "POST",
        data: {
          email: emailInputRef.current.value,
          password: passInputRef.current.value,
        },
      });

      if (response.status == 200) {
        // console.log(response?.data);
        localStorage.setItem("masterAdminEmail", response.data.email);
        localStorage.setItem("masterAdminToken", response.data.token);
        navigate("/university");
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      alert("Error while Logging in: " + error?.response?.data?.message);
      setIsLoading(false);
    }
  }
  return (
    <div className="w-screen h-screen bg-slate-50 flex items-center justify-center  ">
      <div className="flex md:p-[25px] flex-col items-center bg-white  h-fit md:min-w-[500px]  w-4/5 md:w-2/5 xl:w-1/5 shadow-xl rounded-2xl">
        {/* <RiAdminFill className="text-4xl mt-2" /> */}
        <img src={logo} alt="" />
        <form onSubmit={handleLogin} className="p-8  space-y-4">
          <label htmlFor="emailId" className=" inline-block w-full">
            Email
            <input
              id="emailId"
              type="email"
              ref={emailInputRef}
              required
              placeholder="Enter email"
              className="form-input w-full rounded-lg"
            />
          </label>
          <label htmlFor="passId" className=" inline-block w-full">
            Password
            <input
              id="passId"
              type="password"
              ref={passInputRef}
              required
              placeholder="Enter Password"
              className="form-input w-full rounded-lg"
            />
          </label>
          <button
            type="submit"
            className="text-2xl hover:bg-[#4d2b15] text-white px-2 py-2 rounded-xl font-bold w-full bg-[#f6b329]"
          >
            {isLoading ? "loading..." : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};
