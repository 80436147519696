import axios from "axios";
import { nanoid } from "nanoid";
import React, { useEffect, useRef, useState } from "react";
import { MdDelete } from "react-icons/md";
import slugify from "slugify";
import AvatarEditorModal from "./AvatarEditorModal";
import DeleteModal from "./DeleteModal";
const Modal = ({ isOpen, onClose, universityId,selectedUniversity }) => {
  console.log(universityId, "universityId");
  const [logo, setLogo] = useState(null);
  const [logoURL, setLogoURL] = useState(null);
  const [colleges, setColleges] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [statements, setStatements] = useState([]);
  const [slug, setSlug] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLogos, setIsLoadingLogos] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [isAvaliable, setIsAvaliable] = useState();
  const [universityName, setUniversityName] = useState("");
  const [logoName, setLogoName] = useState("");
  const [croppedImage, setcroppedImage] = useState(null);
  const [croppedImageRaw, setcroppedImageRaw] = useState(null);
  const [isOpenModal, setisOpenModal] = useState(false);
  const [universityLogos, setuniversityLogos] = useState([]);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteObj, setDeleteObj] = useState({
    type: "logo",
    title: "",

    id: null,
  });
  const [banner, setBanner] = useState();
  const formRef = useRef();
  const inputFileRef = useRef(null);
  console.log(selectedUniversity,"selected university...")

  async function handleSubmit(e) {
    e.preventDefault();
    if (croppedImageRaw) {
      setIsLoading(true);
      // e.preventDefault();
      const formData = new FormData();
      formData.append("universityId", universityId);
      formData.append("name", logoName);

      const images = [croppedImageRaw];
      // console.log(images);
      images.forEach((image) => {
        formData.append("images", image);
      });

      try {
        let response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/admin/university/addLogo`,
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
            email: localStorage.getItem("masterAdminEmail"),
            token: localStorage.getItem("masterAdminToken"),
          },
          data: formData,
        });
        setIsLoading(false);
        if (response.status == 200) {
          console.log(response.data);
          getAllLogos();
          clearLogo();
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    } else {
      e.preventDefault();
      alert("Please select a logo");
      setIsLoading(false);
    }
  }
  const getAllLogos = async () => {
    setIsLoadingLogos(true);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/admin/university/logos/${universityId}`,
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          email: localStorage.getItem("masterAdminEmail"),
          token: localStorage.getItem("masterAdminToken"),
        },
      });
      setIsLoadingLogos(false);
      if (response.status == 200) {
        console.log(response.data);
        setuniversityLogos(response.data.universityLogos);
      }
    } catch (error) {
      setIsLoadingLogos(false);
      console.log(error);
    }
  };
  async function handleDelete(id, name) {
    // console.log(id);
    setDeleteObj((pre) => ({
      ...pre,
      id: id,
      universityId,
      title: `Are you sure you want to delete ${name} logo?`,
    }));
    setIsDeleteModal(true);
  }
  console.log(deleteObj, "delete obj");

  function handleLogo(e) {
    // console.log(e.target.files[0]);
    if (e.target.files[0]) {
      setLogo(e.target.files[0]);
      setLogoURL(URL.createObjectURL(e.target.files[0]));
      setisOpenModal(true);
    }
  }
  function clearLogo() {
    setLogo(null);
    setLogoURL(null);
    setcroppedImageRaw(null);
    setcroppedImage(null);
    setLogoName("");
    // Reset the value of the file input by resetting the form
    inputFileRef.current.value = "";
  }
  const onCloseModal = () => {
    clearLogo();
    setisOpenModal(false);
  };
  console.log(croppedImageRaw, "cropped imge raw");
  useEffect(() => {
    getAllLogos();
  }, [universityId]);

  return (
    <>
      {isOpen ? (
        <div
          className={` ${
            !isOpen ? "hidden" : "block"
          } fixed inset-0 z-30 overflow-y-auto`}
        >
          <div className="flex min-h-screen items-center justify-center p-4">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-black opacity-75"></div>
            </div>
            <div className="relative flex max-h-full w-screen flex-col overflow-y-scroll rounded-lg bg-white p-[25px]  md:mx-auto md:w-fit md:min-w-[500px] md:overflow-y-auto md:p-6">
              <div className="mb-4">
                <h2 className="text-xl font-bold">{"Add / View Logos"}</h2>
              </div>
              <div className="">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(e);
                    e.stopPropagation();
                  }}
                  ref={formRef}
                  className="space-y-4 divide-y-2"
                >
                  <div className="flex flex-col flex-wrap  justify-center  space-y-4  md:justify-normal mb-5">
                    <div className="flex items-center space-x-2">
                      <img
                        className="h-16 w-16 rounded-full object-cover"
                        src={
                          logo && croppedImageRaw
                            ? URL.createObjectURL(croppedImageRaw)
                            : "https://upload.wikimedia.org/wikipedia/commons/2/24/Circle-icons-image.svg"
                        }
                        alt="Current profile photo"
                      />
                      <label className="block ">
                        <span className="font-semibold">
                          Choose Organization Logo
                        </span>
                        <input
                          type="file"
                          ref={inputFileRef}
                          accept="image/*"
                          onChange={handleLogo}
                          className="file:bg-gray-50-50 block w-full text-sm text-slate-500 file:mr-4 file:rounded-full file:border-0 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-gray-700 hover:file:bg-gray-100"
                        />
                      </label>
                      <input
                        required
                        type="text"
                        id="uniName"
                        value={logoName}
                        onChange={(e) => setLogoName(e.target.value)}
                        className="form-input h-fit w-fit rounded-lg"
                        placeholder="Enter Logo Name"
                      />
                      <button
                        disabled={isLoading}
                        type="submit"
                        className="  rounded  bg-black ml-5 px-5 py-2 text-white hover:bg-gray-600"
                      >
                        {isLoading ? "Loading..." : "Add"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div>
                <div className="mt-5 mb-4">
                  <h2 className="text-xl font-bold">{"Organization Logos"}</h2>
                  <div className="max-h-[400px] overflow-y-scroll">
                    <div className=" flex w-full mt-2 mb-2  items-center justify-between space-y-2 rounded-lg bg-white p-4 shadow-lg">
                      <div className="flex items-center gap-3">
                        <img
                          className="h-16 w-16 rounded-full object-cover"
                          src={
                            selectedUniversity?.universityLogo
                              ? `${process.env.REACT_APP_BASE_URL}/${selectedUniversity?.universityLogo}`
                              : "https://upload.wikimedia.org/wikipedia/commons/2/24/Circle-icons-image.svg"
                          }
                          alt="logo"
                        />
                        <div>
                          <h1 className="md:text-3xl text-xl  font-bold">
                            Primary Logo
                          </h1>
                        </div>
                      </div>
                      
                    </div>
                    {isLoadingLogos ? (
                      <h1 className="text-xl self-center justify-self-center w-full h-full">
                        Loading...
                      </h1>
                    ) : universityLogos.length ? (
                      universityLogos.map((logo, index) => (
                        <div
                          key={logo?.name + index}
                          className=" flex w-full mt-2 mb-2  items-center justify-between space-y-2 rounded-lg bg-white p-4 shadow-lg"
                        >
                          <div className="flex items-center gap-3">
                            <img
                              className="h-16 w-16 rounded-full object-cover"
                              src={
                                logo && logo?.url
                                  ? `${process.env.REACT_APP_BASE_URL}/${logo?.url}`
                                  : "https://upload.wikimedia.org/wikipedia/commons/2/24/Circle-icons-image.svg"
                              }
                              alt="logo"
                            />
                            <div>
                              <h1 className="md:text-3xl text-xl  font-bold">
                                {logo?.name}
                              </h1>
                            </div>
                          </div>
                          <div className="flex  justify-center space-x-4 space-y-2 ">
                            <button
                              onClick={() => handleDelete(logo?.id, logo?.name)}
                              className="text-4xl self-center text-red-500 hover:scale-125"
                            >
                              <MdDelete />
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <h1 className="text-xl self-center justify-self-center w-full h-full">
                        No Logos found.
                      </h1>
                    )}
                  </div>
                </div>

                <div className="flex justify-end w-full py-2 space-x-2">
                  <button
                    className="  rounded  bg-gray-500 px-4 py-2 text-white hover:bg-gray-400"
                    onClick={() => {
                      setLogo();
                      setcroppedImage();
                      setcroppedImageRaw();
                      setIsLoading(false);
                      onClose();
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <AvatarEditorModal
        isOpenModal={isOpenModal}
        setisOpenModal={setisOpenModal}
        onCloseModal={onCloseModal}
        logoURL={logoURL}
        croppedImage={croppedImage}
        setcroppedImage={setcroppedImage}
        setcroppedImageRaw={setcroppedImageRaw}
      />
      <DeleteModal
        isOpen={isDeleteModal}
        onClose={async () => {
          setIsDeleteModal(false);
          getAllLogos();
        }}
        deleteObj={deleteObj}
      />
    </>
  );
};

export default Modal;
