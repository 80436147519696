import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";
import { Route, Routes, useNavigate } from "react-router-dom";
import { University } from "./University";
import { Collages } from "./Collages";
import { Students } from "./Students";
import { Statements } from "./Statements";
import { Admins } from "./Admins";
import { StatementCategory } from "./StatementCategory";

function Home() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("university");
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/${currentTab}`);
  }, [currentTab]);

  return (
    <div className="relative flex h-screen w-screen">
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
      <div className="flex h-full w-full flex-col items-center overflow-y-scroll bg-slate-50">
        <Navbar setSidebaar={setIsSidebarOpen} />
        <Routes>
          <Route path="/university" element={<University />} />
          <Route path="/college" element={<Collages />} />
          <Route path="/statement" element={<Statements />} />
          <Route path="/statement-categories" element={<StatementCategory />} />
          <Route path="/admin" element={<Admins />} />
          <Route path="/student" element={<Students />} />
        </Routes>
      </div>
    </div>
  );
}

export default Home;
